const Config: any = {
  BaseUrlDev: "https://api.solusinegeri.com/",
  BaseUrlProd: "https://api.solusinegeri.com/",
};
let baseUrl = "";
export const check = () => {
  if (process.env.NODE_ENV == "production") {
    baseUrl = Config.BaseUrlProd;
  } else {
    baseUrl = Config.BaseUrlDev;
  }
  return baseUrl;
};
export default Config;
