import Config, { check } from "../../config/Config";
import ErrorHandler from "./errorHandler";
import { setupInterceptorsTo } from "./Interceptors";
import axios from "axios";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
import _ from "lodash";
setupInterceptorsTo(axios);

const baseUrlDev = check();
const bearer = () => {
  var company = JSON.parse(localStorage.getItem("company_redux"));
  var newToken = _.find(JSON.parse(localStorage.getItem("token_redux")), {
    cid: company.initial,
  });
  return newToken.token;
};
export const checkCompany = (initial) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}user/member/company`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        ErrorHandler(err);
        reject(err);
      });
  });
};

export const login = (data, otp) => {
  return new Promise((resolve, reject) => {
    if(otp === ""){
      axios
      .post(`${baseUrlDev}authentication/member/login`, data, {})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    } else {
      axios
      .post(`${baseUrlDev}authentication/member/login`, data, {
        headers: {
          OTP: otp,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    }
  });
};
export const confirmasi_email = (query) => {
  let url = `${baseUrlDev}mob_user/confirm_email?email=${query.email}`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${url}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const confirmasi_otp = (query) => {
  let url = `${baseUrlDev}mob_user/validation_email?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${url}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const confirmation_otp_login = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}mob_user/otp_login`, data, {
        headers: {},
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cekToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}authentication/member/token`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}api_default/auth/logout`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgotPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}mob_user/get_otp/user_membership_member?email=${data.email}&companyId=${data.companyId}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inputOtp = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}mob_user/forgot_password/user_membership_member/${data.otp}`,
        data
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}api_default/auth/user_change_password_first_login/user_membership_member`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createSecurityCode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}mob_user/security_code`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConfigSelfRegister = (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}mob_user/get_config_self_register/${companyId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resend_otp = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}mob_user/get_otp/user_membership_member?email=${data.email}&companyId=${data.companyId}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const requestOtpSelfRegister = (query) => {
  let url = `${baseUrlDev}mob_user/request_otp_self_register?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .post(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const selfRegister = (query, data) => {
  let url = `${baseUrlDev}mob_user/send_self_registration_data?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
