import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const listTransactions = (query) => {
  let url = `${baseUrlDev}digiprod/member/transaction?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadPDF = (id) => {
  let url = `${baseUrlDev}digiprod/member/pdf/order/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Content-Type": "application/octet-stream",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTransactionById = (id) => {
  let url = `${baseUrlDev}digiprod/member/transaction/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// PRABAYAR

export const listPrepaid = (query) => {
  let url = `${baseUrlDev}digiprod/member/pulsa/priceList/prepaid?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxPrepaid = (data, token) => {
  let url = `${baseUrlDev}digiprod/member/pulsa/prepaid`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Re-Authentication": token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//PASCABAYAR

export const inquiryPostpaid = (query) => {
  let url = `${baseUrlDev}digiprod/member/pulsa/inquiry/postpaid?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxPostpaid = (data, token) => {
  let url = `${baseUrlDev}digiprod/member/pulsa/postpaid`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Re-Authentication": token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// PLN

export const tokenPriceList = (query) => {
  let url = `${baseUrlDev}digiprod/member/pln/token/priceList`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxTokenSummary = (query) => {
  let url = `${baseUrlDev}digiprod/member/pln/token/summary?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxToken = (data, token) => {
  let url = `${baseUrlDev}digiprod/member/pln/token`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Re-Authentication": token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxPlnPostpaidInquiry = (query) => {
  let url = `${baseUrlDev}digiprod/member/pln/postpaid/inquiry?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxplnPostpaid = (data, token) => {
  let url = `${baseUrlDev}digiprod/member/pln/postpaid`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Re-Authentication": token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//PDAM

export const pdamClusterList = (query) => {
  let url = `${baseUrlDev}digiprod/code/pdam/cluster?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const pdamList = (query) => {
  let url = `${baseUrlDev}digiprod/code/pdam?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxPDAMInquiry = (query) => {
  let url = `${baseUrlDev}digiprod/member/pdam/inquiry?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxPDAM = (data, token) => {
  let url = `${baseUrlDev}digiprod/member/pdam`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Re-Authentication": token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//BPJS

export const trxBPSJInquiry = (query) => {
  let url = `${baseUrlDev}digiprod/member/bpjs/inquiry?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxBPSJ = (data, token) => {
  let url = `${baseUrlDev}digiprod/member/bpjs`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Re-Authentication": token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// InternetTV

export const internetTvList = () => {
  let url = `${baseUrlDev}digiprod/code/internetTv`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxInternetTvInquiry = (query) => {
  let url = `${baseUrlDev}digiprod/member/internetTv/inquiry?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxInternetTv = (data, token) => {
  let url = `${baseUrlDev}digiprod/member/internetTv`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Re-Authentication": token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//PBB

export const pbbClusterList = (query) => {
  let url = `${baseUrlDev}digiprod/code/pbb/cluster?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const pbbList = (query) => {
  let url = `${baseUrlDev}digiprod/code/pbb?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxPBBInquiry = (query) => {
  let url = `${baseUrlDev}digiprod/member/pbb/inquiry?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const trxPBB = (data, token) => {
  let url = `${baseUrlDev}digiprod/member/pbb`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Re-Authentication": token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const initPPOB = () => {
  let url = `${baseUrlDev}digiprod/member/save`;
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${url}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
