import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import * as API from "../../core/service/api.js";
import {} from "../../core/feature/store";
import { useAppSelector, useAppDispatch } from "../../core/feature/hooks";
import {
  removeCompany,
  setCompany,
} from "../../core/feature/company/companySlice";

import Company from "../../core/models/company";
import { Link, useNavigate } from "react-router-dom";
import { setLoading } from "../../core/feature/config/configSlice";
import { BrowserView, MobileView } from "react-device-detect";
import ProfileWeb from "./profileWeb";
import ProfileMobile from "./profileMobile";

const Profile = () => {
  return (
    <>
      <BrowserView>
        <ProfileWeb></ProfileWeb>
      </BrowserView>
      <MobileView>
        <ProfileMobile></ProfileMobile>
      </MobileView>
    </>
  );
};

export default Profile;
