import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import * as API from "../../core/service/api.js";
import {} from "../../core/feature/store";

import bgClosepay from "../../assets/images/bgClosepay.png";
import merchant from "../../assets/images/icon_bank/merchant.svg";
import bni46 from "../../assets/images/icon_bank/bni46.svg";
import others from "../../assets/images/icon_bank/others.svg";

import {} from "../../core/feature/store";
import edit_icon from "../../assets/images/Edit_Icon.svg";
import person from "../../assets/images/person.png";

import arrow from "../../assets/images/icon_bank/arrow.svg";
import Password from "../../assets/images/Password.png";
import Unlock from "../../assets/images/Unlock.png";
import ShieldDone from "../../assets/images/ShieldDone.png";
import Voice from "../../assets/images/Voice.png";
import Work from "../../assets/images/Work.png";
import UserSupport from "../../assets/images/2_User.png";
import Logout from "../../assets/images/Logout.png";

import Header from "../__template/headerWeb/headerComponent";
import FooterProfileWeb from "../__template/footerWeb/footerProfileComponent";

const ProfileWeb = () => {
  
  return (
    <>
      <Header></Header>
      <section className="w-full bg-gray-100 h-screen" >
        <div className="m-auto max-w-8xl w-4/5  py-12 font-poppins flex justify-between">
          <div className=" mx-4 rounded-xl  w-3/5" style={{ backgroundColor: "#FFFFFF" }}>            
            <div className="flex flex-col gap-3">
                <div className="bg-white px-4 py-3 rounded-xl items-center gap-1">
                  <div>
                    <h5 className="grow font-bold text-lg mt-2 mb-3 m-0">Pengaturan Akun</h5>
                  </div>
                  <div className="flex-grow border-t border-gray-200 m-2"></div>
                  <div className="flex flex-row items-center gap-2 h-14">
                    <img className="grow-0 w-12 h-12" src={Unlock} alt="" />
                    <h1 className="grow font-normal text-base m-0">Change Password</h1>
                    <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
                  </div>
                  <div className="flex-grow border-t border-gray-200 m-2"></div>
                  <div className="flex flex-row items-center gap-2 h-14">
                    <img className="grow-0 w-12 h-12" src={Voice} alt="" />
                    <h1 className="grow font-normal text-base m-0">Change Language</h1>
                    <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
                  </div>
                  <div className="flex-grow border-t border-gray-200 m-2"></div>
                  <div className="flex flex-row items-center gap-2 h-14">
                    <img className="grow-0 w-12 h-12" src={Work} alt="" />
                    <h1 className="grow font-normal text-base m-0">Term of Service</h1>
                    <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
                  </div>
                  <div className="flex-grow border-t border-gray-200 m-2"></div>
                  <div className="flex flex-row items-center gap-2 h-14">
                    <img className="grow-0 w-12 h-12" src={UserSupport} alt="" />
                    <h1 className="grow font-normal text-base m-0">Support</h1>
                    <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
                  </div>
                  <div className="flex-grow border-t border-gray-200 m-2"></div>
                  <div className="flex flex-row items-center gap-2 h-14">
                    <img className="grow-0 w-12 h-12" src={Logout} alt="" />
                    <h1 className="grow font-normal text-base m-0">Logout</h1>
                    <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
                  </div>
                </div>
            </div>
          </div>
          <div className=" mx-4 relative bg-white h-100 rounded-xl flex flex-col w-5/6" style={{ backgroundColor: "#FFFFFF" }}>       
              <div className="relative bg-green1 rounded-xl p-4 grid grid-cols-3 gap-1 items-center justify-center ">
                <img
                  src={bgClosepay}
                  alt=""
                  className="absolute w-48 right-4 top-4"
                />
                <div className="items-center h-fit flex flex-row col-span-2 gap-1">
                  <div className="items-center">
                    <img src={person} alt="user" className="rounded-lg h-24"/>
                  </div>
                  <div className="p-1 flex flex-col col-span-2">
                    <p className="text-white text-lg font-bold m-0 p-0 mb-1">Reseller Aqwam</p>
                    <p className="text-white text-base m-0 p-0">085-xxx-xxx-xxx</p>
                  </div>
                </div>              
                <div className="items-center h-fit flex flex-col gap-1 p-2" style={{justifySelf: 'end'}}>
                  <img src={edit_icon} className="w-12" alt="" />
                  <p className="text-white text-base font-normal m-0">Edit</p>
                </div>
              </div>
              <div className="py-1 px-3">
                <p className="text-sm font-poppins font-bold p-2 mt-2 m-0">
                  Informasi Profile
                </p>
                <div className="flex-grow border-t border-gray-300 mx-2 my-2"></div>
                <div className="grid grid-cols-2 gap-1 p-1">
                  <div className="col-span-1 text-left justify-left">
                    <p className="text-base font-poppins p-2 m-0">
                      Username 
                    </p>
                    <p className="text-base font-poppins p-2 m-0">
                      Email 
                    </p>
                    <p className="text-sm font-poppins p-2 m-0">
                      Telp  
                    </p>
                    <p className="text-base font-poppins p-2 m-0">
                      Tipe Customer 
                    </p>
                    <p className="text-base font-poppins p-2 m-0">
                      Capain Diskon 
                    </p>
                    <p className="text-base font-poppins p-2 m-0">
                      Alamat 
                    </p>
                  </div>
                  <div className="col-span-1 text-right justify-right">
                    <p className="text-base font-poppins font-bold p-2 m-0">
                    user_001
                    </p>
                    <p className="text-base font-poppins font-bold p-2 m-0">
                    bayu.biru@gmail.com 
                    </p>
                    <p className="text-base font-poppins font-bold p-2 m-0">
                    0821-xxxx-xxxx  
                    </p>
                    <p className="text-base font-poppins font-bold p-2 m-0">
                    Reseller
                    </p>
                    <p className="text-base font-poppins font-bold p-2 m-0">
                    30%
                    </p>
                    <p className="text-base font-poppins font-bold p-2 m-0">
                    Jl. Rajawali Raya, Geduren, Gonilan, Kartasura, Sukoharjo
                    </p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      <FooterProfileWeb></FooterProfileWeb>
    </>
  );
};

export default ProfileWeb;
