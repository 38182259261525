import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import desktopBackground from "../../assets/images/desktopBackground.png";
import closepayLogo from "../../assets/images/bgClosepay.png";
import brangkas from "../../assets/images/brangkas.png";
import bsi from "../../assets/images/bsi.png";
import * as API from "../../core/service/api.js";
import {} from "../../core/feature/store";

import donasi_icon from "../../assets/images/icon_menu/donasi_icon.svg";
import lainnya_icon from "../../assets/images/icon_menu/lainnya_icon.svg";
import marketplace_icon from "../../assets/images/icon_menu/marketplace_icon.svg";
import ppob_icon from "../../assets/images/icon_menu/ppob_icon.svg";
import saldo_icon from "../../assets/images/icon_menu/saldo_icon.svg";
import smart_access_icon from "../../assets/images/icon_menu/smart_access_icon.svg";
import support_icon from "../../assets/images/icon_menu/support_icon.svg";
import virtual_card_icon from "../../assets/images/icon_menu/virtual_card_icon.svg";
import dark_icon from "../../assets/images/icon_balance/dark_icon.svg";
import invoice_icon from "../../assets/images/icon_menu/invoice_icon.svg";
import mutation_icon from "../../assets/images/icon_menu/mutation_icon.svg";
import topup_icon from "../../assets/images/icon_menu/topup_icon.svg";
import transfer_icon from "../../assets/images/icon_menu/transfer_icon.svg";
import user_icon from "../../assets/images/userNew.png";
import arrow_balance_icon from "../../assets/images/icon_balance/arrow_balance.svg";
import qrcode_icon from "../../assets/images/qrcode_icon.svg";
import home_active from "../../assets/images/icon_nav/home-active.svg";
import chart from "../../assets/images/icon_nav/Chart.svg";
import message from "../../assets/images/icon_nav/Message.svg";
import add_user from "../../assets/images/icon_nav/Add User.svg";

import FooterHomeMobile from "../__template/footerMobile/footerHomeComponent";
import IconProfileHijauPutih from "../../assets/images/IconProfileHijauPutih.svg";

const HomeMobile = () => {
  const feature = [
    {
      name: "Invoice",
      icon: donasi_icon,
    },
    {
      name: "Transaksi",
      icon: marketplace_icon,
    },
    {
      name: "History",
      icon: ppob_icon,
    },
    {
      name: "Tagihan",
      icon: saldo_icon,
    },
    {
      name: "Managemen Customer",
      icon: virtual_card_icon,
    },
    {
      name: "Affiliasi",
      icon: smart_access_icon,
    },
    {
      name: "Pre Order",
      icon: support_icon,
    },
    {
      name: "Whislist",
      icon: lainnya_icon,
    },
  ];
  const buku_po = [
    {
      title: "PO My Sweet Ramadhan",
      img: "https://admin.aqwam.com/storage/230103_10222786_promo_230103_032143_01489.jpeg",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "PO Menulis Al-Quran",
      img: "https://admin.aqwam.com/storage/230120_211314765_promo_230120_020741_01523.jpeg",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "PO Seri Belajar Adab dengan Cerita Berima",
      img: "https://admin.aqwam.com/storage/230117_10726791_promo_230117_030710_01514.jpeg",
      create_time: "2 Menit yg lalu",
    },
  ];
  const news = [
    {
      title: "Flash Sale 2-10 Februari",
      img: "https://admin.aqwam.com/storage/221228_14448604.jpeg",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Promo Besar di Aqwam Store",
      img: "https://admin.aqwam.com/storage/230105_1046955.jpeg",
      create_time: "2 Menit yg lalu",
    },
  ];
  return (
    <>
      <div className="container h-fit font-poppins p-0 bg-first dark:bg-black">
        <section className="bg-hero-pattern dark:bg-hero-pattern-dark p-4">
          <img
            src={closepayLogo}
            alt=""
            className="absolute right-0 w-52 top-5"
          />
          <div className="flex p-3">
            <div className="flex flex-col gap-1 grow">
              <h1 className="text-white text-sm font-medium m-0 p-0">
                Selamat Datang
              </h1>
              <div className="flex items-center gap-2">
                <p className="text-white text-lg font-semibold m-0 p-0">
                  Reseller Aqwam
                </p>
                <img src={arrow_balance_icon} alt="dark_icon" className="w-4" />
              </div>
            </div>
            <div className="flex flex-0 items-center gap-3 ">
              <img src={dark_icon} alt="dark_icon" className="w-7" />
              <img src={IconProfileHijauPutih} alt="dark_icon" className="w-9" />

              {/* <button
                className="bg-white px-2 py-1 h-7 rounded-full text-xs"
                style={{ color: "#02A880" }}
              >
                Login/Daftar
              </button> */}
            </div>
          </div>
          <div className="flex flex-row items-center gap-4 justify-center m-3">
            <div className="flex flex-col gap-1 grow items-center">
              <p className="font-normal text-lg text-white  mb-0">2115</p>
              <p className="font-normal text-xs text-white">Poin</p>
            </div>
            <div className="flex flex-col grow gap-1 items-center">
              <p className="font-normal text-lg text-white mb-0">179</p>
              <p className="font-normal text-xs text-white">Transaksi</p>
            </div>
            <div className="flex flex-col grow gap-1 items-center">
              <p className="font-normal text-lg text-white mb-0">Rp. 195.000.000</p>
              <p className="font-normal text-xs text-white">Total Transaksi</p>
            </div>
            {/* <div className="flex flex-col grow gap-3 items-center">
              <img src={transfer_icon} alt="dark_icon" className="h-8" />
              <p className="font-normal text-xs text-white">Transfer</p>
            </div> */}
          </div>

          <div className="bg-white1 dark:bg-black rounded-lg p-3">
            <h1 className="font-medium text-sm text-secondary  dark:text-white">
              Feature
            </h1>
            <div className="grid grid-cols-4 gap-2 justify-center">
              {feature.map((item: any) => (
                <div className="flex flex-col gap-3 items-center py-2 ">
                  <img src={item.icon} alt="dark_icon" className="h-12" />
                  <p
                    className="font-medium m-0 text-center text-thirdy dark:text-white"
                    style={{ fontSize: 9 }}
                  >
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="px-6 py-2">
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <h1 className=" text-base font-medium m-0 text-secondary dark:text-white">
                Buku Pre Order
              </h1>
              <button className=" text-blue-600 text-xs">Lihat Semua</button>
            </div>
            <p className="text-xs font-light text-gray-500 dark:text-white text-left">
              Berikut buku pre order terbaru
            </p>
          </div>
          <div className="mb-2">
            <div className="flex overflow-x-auto max-h-50 flex-nowrap">
              <div className="flex flex-nowrap gap-3 ">
                {buku_po.map((item) => (
                  <div
                    className="mb-4 w-60 h-40 shadow-sm rounded-xl  overflow-hidden relative"
                    style={{
                      backgroundImage: `url(${item.img})`,
                      backgroundPosition: "top",
                      backgroundSize: "contain",
                    }}
                  >
                    <div className="flex flex-col px-4 py-2 mt-9 gap-2 absolute bottom-px">
                      <h1 className=" text-base font-semibold text-white text-left m-0" style={{textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF"}}>
                        {item.title}
                      </h1>
                      {/* <p className="text-xs font-light text-white text-left m-0">
                        {item.create_time}
                      </p> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="px-6 py-2">
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <h1 className=" text-base font-medium m-0 text-secondary dark:text-white">
                Informasi
              </h1>
              <button className=" text-blue-600 text-xs">Lihat Semua</button>
            </div>
            <p className="text-xs font-light text-gray-500 dark:text-white text-left">
              Berikut informasi terbaru
            </p>
          </div>
          <div className="mb-10">
            <div className="flex overflow-x-auto max-h-50 flex-nowrap">
              <div className="flex flex-nowrap gap-3 ">
                {news.map((item) => (
                  <div
                    className="mb-4 w-60 h-40 shadow-sm rounded-xl  overflow-hidden relative"
                    style={{
                      backgroundImage: `url(${item.img})`,
                      backgroundPosition: "top",
                      backgroundSize: "contain",
                    }}
                  >
                    <div className="flex flex-col px-4 py-2 mt-9 gap-2 absolute bottom-px">
                      <h1 className=" text-base font-semibold text-white text-left m-0" style={{textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF"}}>
                        {item.title}
                      </h1>
                      {/* <p className="text-xs font-light text-white text-left m-0">
                        {item.create_time}
                      </p> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <FooterHomeMobile></FooterHomeMobile> 
      </div>
    </>
  );
};

export default HomeMobile;
