import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState, useEffect } from "react";

import "react-multi-carousel/lib/styles.css";
import Info from "../../../assets/images/Info.png";
import Notification from "../../../assets/images/Notification.png";
import userDefault from "../../../assets/images/userDefault.png";
import userNew from "../../../assets/images/userNew.png";
import { useAppSelector } from "../../../core/feature/hooks";
import Company from "../../../core/models/company";
import User from "../../../core/models/user";
import closepayLogo from "../../../assets/images/closepayLogo.svg";
import IconProfileHijauPutih from "../../../assets/images/IconProfileHijauPutih.svg";

import info from "../../../assets/images/info.svg";
import notification from "../../../assets/images/notification.svg";
import closepay_white from "../../../assets/images/icon_closepay/closepay-white.svg";
import {
  faSearch,
  faHome,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Token from "../../../core/models/token";
import { find } from "lodash";
interface HeaderProps {
  navbarBrand?: any;
  navbarText?: string;
  backgroundStyle?: string;
  navigateTo?: any;
}

const Header: FC<HeaderProps> = (props): JSX.Element => {
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newtoken = find(token, { cid: company?.initial });
  const navigate = useNavigate();

  const tabs = [
    {
      route: "/home",
      icon: faSearch,
      label: "Home",
    },
    {
      route: "/search",
      icon: faSearch,
      label: "Search",
    },
    {
      route: "/login",
      icon: faSearch,
      label: "Login",
    },
  ];

  return (
    <>
      <section>        
          <div className="mx-auto max-w-8xl w-4/5 flex py-4 items-center">
            <div className="grow">
              <p className="m-0 font-semibold text-3xl text-green">
                Dashboard Reseller
              </p>
            </div>
            <div className="flex grow-0 gap-2 items-center">
              <div className="flex flex-col gap-2 items-center">
                <img src={notification} alt="" className="w-8 h-8" />
              </div>
              <div className="flex gap-3 items-center ml-4">
                <p className="text-pHeder font-light text-lg m-0">Reseller Aqwam</p>
                <img src={IconProfileHijauPutih} alt="" className="w-12 h-12" />
              </div>
            </div>
          </div>
      </section>
    </>
  );
};

export default Header;
