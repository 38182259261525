import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./assets/style/custom.css";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Routing from "./Routes";
import "bootstrap/dist/css/bootstrap.css";
import "./config/font.css";
import LoadingOverlay from "react-loading-overlay-ts";
import { useAppSelector } from "./core/feature/hooks";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Company from "../src/core/models/company";
import closepayLogo from "./assets/images/single-logo-closepay.png";

function App() {
  let isLoading: boolean = useAppSelector((state) => state.config.loading);
  const company: Company = useAppSelector((state) => state.company.company);
  const [title, setTitle] = useState("Closepay");
  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  useEffect(() => {
    // setTitle(company?.name);
    const favicon: any | null = getFaviconEl();

    // favicon.href = company?.logo != null ? company?.logo : closepayLogo;
    favicon.href = closepayLogo;
    let companyName = company?.name as string;
    document.title = companyName != null ? companyName : "Micro Service";
  }, [company]);
  return (
    // <div className="App-full h-full min-h-screen bg-second dark:bg-black">
      <div>
        <ToastContainer></ToastContainer>
        <LoadingOverlay active={isLoading} spinner text="Loading ...">
          <Routes>
            {Routing.map((prop: any, index: any) => {
              if (prop.path == "") {
                return (
                  <Route
                    key={`menu-${index}`}
                    path="/"
                    element={<Navigate to={prop.redirect} replace />}
                  />
                );
              } else {
                return (
                  <Route
                    key={"menu-" + index}
                    path={prop.path}
                    element={<prop.component />}
                  />
                );
              }
            })}
          </Routes>
        </LoadingOverlay>
      </div>
    // </div>
  );
}

export default App;
