import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Token from "../../models/token";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";

type InitialState = {
  token: Token[]
}
const type = 'token'

const initialState: InitialState = {
  token: loadState(type)
}

export const tokenSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setToken: (state, action: PayloadAction<Token[]>) => {
      state.token = action.payload;
      saveState(type, action.payload)
    },
    removeToken: (state) => {
      state.token = [{}];
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, removeToken } = tokenSlice.actions;

export default tokenSlice.reducer;
