import Home from "./components/homeComponent";
import Profile from "./components/profileComponent";

const Routes: any = [
  {
    path: "",
    redirect: "home",
  },
  {
    path: "home",
    component: Home,
  },
  {
    path: "profile",
    component: Profile,
  }
];
export default Routes;
