import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import bgClosepay from "../../assets/images/bgClosepay.png";
import back from "../../assets/images/arrow-left.svg";
import edit_icon from "../../assets/images/Edit_Icon.svg";
import person from "../../assets/images/person.png";
import merchant from "../../assets/images/icon_bank/merchant.svg";
import bni46 from "../../assets/images/icon_bank/bni46.svg";
import others from "../../assets/images/icon_bank/others.svg";
import arrow from "../../assets/images/icon_bank/arrow.svg";
import {} from "../../core/feature/store";

import FooterProfilMobile from "../__template/footerMobile/footerProfileComponent";

import Password from "../../assets/images/Password.png";
import Unlock from "../../assets/images/Unlock.png";
import ShieldDone from "../../assets/images/ShieldDone.png";
import Voice from "../../assets/images/Voice.png";
import Work from "../../assets/images/Work.png";
import UserSupport from "../../assets/images/2_User.png";
import Logout from "../../assets/images/Logout.png";


const ProfileMobile = () => {
  const bank = [
    { name: "BNI", img: bni46 },
    { name: "Bank Lainnya", img: others },
    { name: "Merchant", img: merchant },
  ];
  return (
    <>
      <div className="container h-fit font-poppins p-0 bg-first dark:bg-black">
        <section className="px-6 pt-6 pb-7">
          <div className="bg-white rounded-2xl  flex flex-col ">            
            <div className="relative bg-green1 rounded-2xl p-4 grid grid-cols-3 gap-1 items-center justify-center ">
              <img
                src={bgClosepay}
                alt=""
                className="absolute w-32 right-4 top-4"
              />
              <div className="items-center h-fit flex flex-row col-span-2 gap-1">
                <div className="items-center">
                  <img src={person} alt="user" className="rounded-lg h-16"/>
                </div>
                <div className="p-1 flex flex-col col-span-2">
                  <p className="text-white text-sm font-bold m-0 p-0 mb-1">Reseller Aqwam</p>
                  <p className="text-white text-xs m-0 p-0">085-xxx-xxx-xxx</p>
                </div>
              </div>              
              <div className="items-center h-fit flex flex-col gap-1" style={{justifySelf: 'end'}}>
                <img src={edit_icon} alt="" />
                <p className="text-white text-xs font-normal m-0">Edit</p>
              </div>
            </div>
            <div className="py-1 px-3">
              <p className="text-xs font-poppins font-bold p-2 m-0">
                Informasi Profile
              </p>
              <div className="flex-grow border-t border-gray-300 mx-2"></div>
              <div className="grid grid-cols-2 gap-1 p-1">
                <div className="col-span-1 text-left justify-left">
                  <p className="text-xs font-poppins p-1 m-0">
                    Username 
                  </p>
                  <p className="text-xs font-poppins p-1 m-0">
                    Email 
                  </p>
                  <p className="text-xs font-poppins p-1 m-0">
                    Telp  
                  </p>
                  <p className="text-xs font-poppins p-1 m-0">
                    Tipe Customer 
                  </p>
                  <p className="text-xs font-poppins p-1 m-0">
                    Capain Diskon 
                  </p>
                  <p className="text-xs font-poppins p-1 m-0">
                    Alamat 
                  </p>
                </div>
                <div className="col-span-1 text-right justify-right">
                  <p className="text-xs font-poppins font-bold p-1 m-0">
                  user_001
                  </p>
                  <p className="text-xs font-poppins font-bold p-1 m-0">
                  bayu.biru@gmail.com 
                  </p>
                  <p className="text-xs font-poppins font-bold p-1 m-0">
                  0821-xxxx-xxxx  
                  </p>
                  <p className="text-xs font-poppins font-bold p-1 m-0">
                  Reseller
                  </p>
                  <p className="text-xs font-poppins font-bold p-1 m-0">
                  30%
                  </p>
                  <p className="text-xs font-poppins font-bold p-1 m-0">
                  Jl. Rajawali Raya, Geduren, Gonilan, Kartasura, Sukoharjo
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="flex flex-col mt-4 gap-3 pb-24">
              <div className="bg-white px-4 py-3 rounded-xl items-center gap-1">
                <div>
                  <h5 className="grow font-bold text-sm mb-2 m-0">Pengaturan Akun</h5>
                </div>
                <div className="flex flex-row items-center gap-2 h-14">
                  <img className="grow-0 w-8 h-8" src={Unlock} alt="" />
                  <h1 className="grow font-normal text-xs m-0">Change Password</h1>
                  <img className="grow-0" src={arrow} alt="" />
                </div>
                <div className="flex flex-row items-center gap-2 h-14">
                  <img className="grow-0 w-8 h-8" src={Voice} alt="" />
                  <h1 className="grow font-normal text-xs m-0">Change Language</h1>
                  <img className="grow-0" src={arrow} alt="" />
                </div>
                <div className="flex flex-row items-center gap-2 h-14">
                  <img className="grow-0 w-8 h-8" src={Work} alt="" />
                  <h1 className="grow font-normal text-xs m-0">Term of Service</h1>
                  <img className="grow-0" src={arrow} alt="" />
                </div>
                <div className="flex flex-row items-center gap-2 h-14">
                  <img className="grow-0 w-8 h-8" src={UserSupport} alt="" />
                  <h1 className="grow font-normal text-xs m-0">Support</h1>
                  <img className="grow-0" src={arrow} alt="" />
                </div>
                <div className="flex flex-row items-center gap-2 h-14">
                  <img className="grow-0 w-8 h-8" src={Logout} alt="" />
                  <h1 className="grow font-normal text-xs m-0">Logout</h1>
                  <img className="grow-0" src={arrow} alt="" />
                </div>
              </div>
          </div>
        </section>
        <FooterProfilMobile></FooterProfilMobile>                
        
      </div>
    </>
  );
};

export default ProfileMobile;
