import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Company from "../../models/company";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";

type InitialState = {
  company: Company
}
const type = 'company'

const initialState: InitialState = {
  company: loadState(type)
}

export const companySlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<Company>) => {
      state.company = action.payload;
      saveState(type, action.payload)
    },
    removeCompany: (state) => {
      state.company = {};
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCompany, removeCompany } = companySlice.actions;

export default companySlice.reducer;
