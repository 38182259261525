import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import HomeMobile from "./homeMobile";
import HomeWeb from "./homeWeb";

const Home = () => {
  return (
    <>
      <BrowserView>
        <HomeWeb></HomeWeb>
      </BrowserView>
      <MobileView>
        <HomeMobile></HomeMobile>
      </MobileView>
    </>
  );
};

export default Home;
