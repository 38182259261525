import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import * as API from "../../core/service/api.js";
import * as api_company from "../../core/service/api_company";
import {} from "../../core/feature/store";
import { useAppSelector, useAppDispatch } from "../../core/feature/hooks";
import {
  removeCompany,
  setCompany,
} from "../../core/feature/company/companySlice";
import closepayLogo from "../../assets/images/closepayLogo.svg";

import Company from "../../core/models/company";
import { setLoading } from "../../core/feature/config/configSlice";
import { NotifIcon } from "../../assets/images/svg/icon_mobile.js";

import invoice from "../../assets/images/icon_menu/invoice.svg";
import payment from "../../assets/images/icon_menu/payment.svg";
import saldo from "../../assets/images/icon_menu/saldo.svg";

import donasi_icon from "../../assets/images/icon_menu/donasi_icon.svg";
import lainnya_icon from "../../assets/images/icon_menu/lainnya_icon.svg";
import marketplace_icon from "../../assets/images/icon_menu/marketplace_icon.svg";
import ppob_icon from "../../assets/images/icon_menu/ppob_icon.svg";
import saldo_icon from "../../assets/images/icon_menu/saldo_icon.svg";
import smart_access_icon from "../../assets/images/icon_menu/smart_access_icon.svg";
import support_icon from "../../assets/images/icon_menu/support_icon.svg";
import virtual_card_icon from "../../assets/images/icon_menu/virtual_card_icon.svg";
import dark_icon from "../../assets/images/icon_balance/dark_icon.svg";
import invoice_icon from "../../assets/images/icon_menu/invoice_icon.svg";
import mutation_icon from "../../assets/images/icon_menu/mutation_icon.svg";
import topup_icon from "../../assets/images/icon_menu/topup_icon.svg";
import transfer_icon from "../../assets/images/icon_menu/transfer_icon.svg";
import closepay_white from "../../assets/images/icon_closepay/closepay-white.svg";
import arrow_balance_icon from "../../assets/images/icon_balance/arrow_balance.svg";
import qrcode_icon from "../../assets/images/qrcode_icon.svg";
import chart from "../../assets/images/icon_nav/Chart.svg";
import message from "../../assets/images/icon_nav/Message.svg";
import add_user from "../../assets/images/icon_nav/Add User.svg";
import topup from "../../assets/images/icon_menu/topup.svg";
import transfer from "../../assets/images/icon_menu/transfer.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import User from "../../core/models/user";
import Token from "../../core/models/token";
import _, { find } from "lodash";
import { removeUser, setUser } from "../../core/feature/user/userSlice";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";
import "./home.scss";
import "react-multi-carousel/lib/styles.css";

import home_active from "../../assets/images/icon_nav/home-active.svg";
import notification from "../../assets/images/icon_nav/notification.svg";
import info from "../../assets/images/icon_nav/info.svg";
import profile from "../../assets/images/icon_nav/profile.svg";
import IconProfileHijauPutih from "../../assets/images/IconProfileHijauPutih.svg";


import userDefault from "../../assets/images/userDefault.png";
import userNew from "../../assets/images/userNew.png";

import FooterHomeWeb from "../__template/footerWeb/footerHomeComponent";

const HomeWeb = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newtoken = find(token, { cid: company?.initial });

  const location = useLocation();
  const { cid } = useParams();

  const [pathName, setPathName] = useState(
    location.pathname.replaceAll("/", "").toUpperCase()
  );
  const [isLogin, setisLogin] = useState(
    newtoken?.token != "" && newtoken?.token != undefined
  );
  const feature = [
    {
      name: "Invoice",
      icon: donasi_icon,
    },
    {
      name: "Transaksi",
      icon: marketplace_icon,
    },
    {
      name: "History",
      icon: ppob_icon,
    },
    {
      name: "Tagihan",
      icon: saldo_icon,
    },
    {
      name: "Managemen Customer",
      icon: virtual_card_icon,
    },
    {
      name: "Affiliasi",
      icon: smart_access_icon,
    },
    {
      name: "Pre Order",
      icon: support_icon,
    },
    {
      name: "Whislist",
      icon: lainnya_icon,
    },
  ];
  const buku_po = [
    {
      title: "PO My Sweet Ramadhan",
      img: "https://admin.aqwam.com/storage/230103_10222786_promo_230103_032143_01489.jpeg",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "PO Menulis Al-Quran",
      img: "https://admin.aqwam.com/storage/230120_211314765_promo_230120_020741_01523.jpeg",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "PO My Sweet Ramadhan",
      img: "https://admin.aqwam.com/storage/230103_10222786_promo_230103_032143_01489.jpeg",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "PO Menulis Al-Quran",
      img: "https://admin.aqwam.com/storage/230120_211314765_promo_230120_020741_01523.jpeg",
      create_time: "2 Menit yg lalu",
    },
  ];
  const news = [
    {
      title: "Flash Sale 2-10 Februari",
      img: "https://admin.aqwam.com/storage/221228_14448604.jpeg",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Promo Besar di Aqwam Store",
      img: "https://admin.aqwam.com/storage/230105_1046955.jpeg",
      create_time: "2 Menit yg lalu",
    },
  ];
  const marketplace = [
    {
      title: "Shampoo 200ml",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Pasta Gigi Pepsodent",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Sabun Cair Lux 800ml",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Shampoo 200ml",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Pasta Gigi Pepsodent",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Sabun Cair Lux 800ml",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Shampoo 200ml",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Pasta Gigi Pepsodent",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Sabun Cair Lux 800ml",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Shampoo 200ml",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Pasta Gigi Pepsodent",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
    {
      title: "Sabun Cair Lux 800ml",
      img: "https://source.unsplash.com/600x400?shop",
      create_time: "2 Menit yg lalu",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 4,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };

  const login = async () => {
    navigate(`/${company.initial}/login`);
  };

  const getCompany = async () => {
    if (cid && !company) {
      await API.checkCompany(cid)
        .then((res) => {
          dispatch(setCompany(res.data.data));
        })
        .catch((err) => {
          // sethidden(true);
          dispatch(removeCompany());
        });
    }
  };
  useEffect(() => {
    console.log("token", isLogin);

    const timer = setTimeout(() => {
      // getCompany();
    }, 750);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (company) {
      console.log(company);
      console.log("check", pathName, company.initial);
      if (pathName != company.initial) {
        var checkToken = _.find(token, { cid: pathName });

        // api_company
        //   .getCompany(pathName)
        //   .then((res) => {
        //     dispatch(setLoading(false));
        //     dispatch(setCompany(res.data.data));
        //     dispatch(removeUser());
        //     console.log("token", checkToken);
        //     if (!token) {
        //       navigate(`/${pathName}`);
        //     } else if (!checkToken && token) {
        //       navigate(`/${pathName}/login`);
        //     }
        //   })
        //   .then((res) => {
        //     if (checkToken) {
        //       API.cekToken().then((res) => {
        //         console.log(res);
        //         dispatch(setUser(res.data));
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     dispatch(setLoading(false));
        //     toast.error("Instansi " + pathName + " Tidak Ditemukan");
        //     getCompany();
        //   });
      }
    } else {
      console.log("masuk sini");

      // API.checkCompany(location.pathname.replaceAll("/", ""))
      //   .then((res) => {
      //     dispatch(setLoading(false));
      //     dispatch(setCompany(res.data));
      //   })
      //   .catch((err) => {
      //     dispatch(setLoading(false));
      //     toast.error("Instansi " + pathName + " Tidak Ditemukan");
      //     navigate("/companies");
      //   });
    }
  }, [pathName]);

  return (
    <>
      <div className=" h-fit font-poppins bg-first">
        <section
          id="header"
          className="bg-header-home sm:w-full h-fit pt-4 px-16 pb-16 border-1 relative mb-40"
        >
          <div className="relative">
            <div className="flex px-24 py-9">
              <div className="grow flex flex-col">
                <p className="m-0 font-semibold text-4xl text-white">
                  Dashboard Reseller
                </p>
              </div>              
              <div className="flex grow-0 gap-2 items-center">
                <div className="flex flex-col gap-2 items-center">
                  <img src={notification} alt="" className="w-8 h-8" />
                </div>
                <div className="flex gap-3 items-center ml-4">
                  <img src={IconProfileHijauPutih} alt="" className="w-12 h-12" />
                </div>
              </div>
            </div>
            <div className="w-2/5 justify-center m-auto ">
              <div className="text-center flex flex-col gap-1 mt-3">
                <h1 className="m-0 font-semibold text-base text-white">
                  Selamat Datang
                </h1>
                <p className="m-0 font-bold text-4xl text-white">
                  Reseller Aqwam
                </p>
              </div>

              <div className="flex flex-row items-center gap-4 justify-center mt-10 mb-1">
                <div className="flex flex-col gap-2 grow items-center">
                  <p className="font-normal text-3xl text-white  mb-0">2115</p>
                  <p className="font-normal text-lg text-white">Poin</p>
                </div>
                <div className="flex flex-col grow gap-2 items-center">
                  <p className="font-normal text-3xl text-white mb-0">179</p>
                  <p className="font-normal text-lg text-white">Transaksi</p>
                </div>
                <div className="flex flex-col grow gap-2 items-center">
                  <p className="font-normal text-3xl text-white mb-0">Rp. 195.000.000</p>
                  <p className="font-normal text-lg text-white">Total Transaksi</p>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute left-1/2 transform -translate-x-1/2 m-auto bg-white1 dark:bg-black rounded-2xl py-3 px-10 w-3/5">
            <h1 className="font-medium text-center text-base text-secondary  dark:text-white underline underline-offset-4">
              Feature
            </h1>
            <div className=" flex  lg:flex-row  xl:flex-row gap-2 justify-center">
              {feature.map((item: any, index: number) => (
                <div className="flex flex-col gap-3 items-center py-2 w-32" key={index}>
                  <img src={item.icon} alt="dark_icon" className="h-16" />
                  <p className="font-normal font-base m-0 text-center text-thirdy dark:text-white">
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className="container h-full font-poppins">
          <section id="buku_po" className="mt-8 pt-3">
            <div className="flex flex-col">
              <div className="flex items-center mb-2 gap-4">
                <h1 className=" text-4xl font-medium m-0">Buku Pre Order</h1>
                <button className="underline text-blue-600 text-base">
                  Lihat Semua
                </button>
              </div>
              <p className="text-xl font-light text-gray-500 text-left">
              Berikut buku pre order terbaru
              </p>
            </div>
            <div className="mb-4">
              <Carousel
                responsive={responsive}
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable={false}
                focusOnSelect={false}
                infinite={false}
                partialVisible={false}
                itemClass="carouselItem"
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={4}
                swipeable
              >
                {buku_po.map((res, index) => (
                  <Link to={""} className="no-underline" key={index}>
                    <div draggable={false} className="mb-4 w-80 mx-w-80">
                      <div className="m-2.5 bg-white rounded-lg overflow-hidden  shadow-sm">
                        <img
                          loading="lazy"
                          draggable={false}
                          src={res.img}
                          className="object-cover w-full select-none overflow-hidden"
                        />
                        <div className="px-4 py-2">
                          <h1 className="text-2xl font-semibold text-black text-left">
                            {res.title}
                          </h1>
                          <p className="text-base font-light text-gray-500 text-left">
                            {res.create_time}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </Carousel>
            </div>
          </section>
          <section id="news" className="my-6 pb-10">
            <div className="flex flex-col">
              <div className="flex items-center mb-2 gap-4">
                <h1 className=" text-4xl font-medium m-0">Berita & Informasi</h1>
                <button className="underline text-blue-600 text-base">
                  Lihat Semua
                </button>
              </div>
              <p className="text-xl font-light text-gray-500 text-left">
              Berikut berita dan informasi terbaru
              </p>
            </div>
            <div className="mb-4">
              <Carousel
                responsive={responsive}
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable={false}
                focusOnSelect={false}
                infinite={false}
                partialVisible={false}
                itemClass="carouselItem"
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={4}
                swipeable
              >
                {news.map((res, index) => (
                  <Link to={""} className="no-underline" key={index}>
                    <div draggable={false} className="mb-4 w-80 mx-w-80">
                      <div className="m-2.5 bg-white rounded-lg overflow-hidden  shadow-sm">
                        <img
                          loading="lazy"
                          draggable={false}
                          src={res.img}
                          className="object-cover w-full select-none overflow-hidden"
                        />
                        <div className="px-4 py-2">
                          <h1 className="text-2xl font-semibold text-black text-left">
                            {res.title}
                          </h1>
                          <p className="text-base font-light text-gray-500 text-left">
                            {res.create_time}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </Carousel>
            </div>
          </section>
        </div>

      
        <FooterHomeWeb></FooterHomeWeb> 
        
      </div>
    </>
  );
};

export default HomeWeb;
