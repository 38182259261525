import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState, useEffect } from "react";

import "react-multi-carousel/lib/styles.css";
import {
  faSearch,
  faHome,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import qrcode_icon from "../../../assets/images/qrcode_icon.svg";

import home_active_new from "../../../assets/images/iconNav/home_active_new.svg";
import message_inactive from "../../../assets/images/iconNav/message_inactive.svg";
import order_inactive from "../../../assets/images/iconNav/order_inactive.svg";
import profile_inactive from "../../../assets/images/iconNav/profile_inactive.svg";

const Footer = () => {

  return (
    <>
      <section>
        <div className="flex gap-0 fixed bottom-0 bg-white px-2 py-2.5 w-full">
          <div className="grow flex flex-col items-center ">
            <img src={home_active_new} alt="" className="w-5 h-5" />
            <h1 className="m-0 text-xs font-normal" style={{color: '#1dc500'}}>Home</h1>
          </div>
          <Link to="/order" className="no-underline grow flex flex-col items-center">
              <img src={order_inactive} alt="" className="w-5 h-5" />
              <h1 className="m-0 text-xs font-normal text-gray-300">Order</h1>
          </Link> 
          <Link to="/informasi" className="no-underline grow flex flex-col items-center">
              <img src={message_inactive} alt="" className="w-5 h-5" />
              <h1 className="m-0 text-xs font-normal text-gray-300"> Informasi</h1>   
          </Link>
          <Link to="/profile" className="no-underline grow flex flex-col items-center">
              <img src={profile_inactive} alt="" className="w-5 h-5" />
              <h1 className="m-0 text-xs font-normal text-gray-300">Profile</h1>
          </Link> 
        </div>
      </section>
    </>
  );
};

export default Footer;
