import * as API_TRANSACTION from "../core/service/api_transaction";
import * as API_COMMERCE from "../core/service/api_commerce";
import * as API_DIGIPROD from "../core/service/api_digiprod";

import moment from "moment";
var idLocale = require("moment/locale/id");
const momentLocal = moment.locale("id", idLocale);

export const createArrayEntityFromObject = (obj) => {
  return Object.keys(obj);
};

export const getEntity = (keys, obj) => {
  return keys
    .map((k) => {
      if (typeof obj[k] == "boolean") {
        // console.log("obj[k]", obj[k], "[k]", [k]);
        return obj[k];
        // return { key: [k], value: obj[k] };
      } else {
        if (obj[k]) return { key: [k], value: obj[k] };
      }
    })
    .filter((el) => el != undefined);
};

export const getEntityPy = (keys, obj) => {
  return keys
    .map((k) => {
      if (typeof obj[k] == "boolean" || typeof obj[k] == "number") {
        // console.log("obj[k]", obj[k], "[k]", [k]);
        // return obj[k];
        return { key: [k], value: obj[k] };
      } else {
        if (obj[k]) return { key: [k], value: obj[k] };
      }
    })
    .filter((el) => el != undefined);
};

export const createQueryUrl = (arr, separator) => {
  let query = "";
  arr.map((el, n) => {
    if (arr.length - 1 > n)
      return (query = `${query}${el.key}=${el.value}${separator}`);
    if (typeof el.value == "boolean" || typeof el.value == "number") {
      // console.log("el", el);
      query = `${query}${el.key}=${el.value}`;
    } else if (el.value) {
      query = `${query}${el.key}=${el.value}`;
    }
  });
  // console.log(query);
  return query;
};

export const findArray = (arr, fromKey, value, returnKey) => {
  if (arr.find((el) => el[fromKey].includes(value))) {
    return arr.find((el) => el[fromKey].includes(value))[returnKey];
  } else {
    return null;
  }
};

export const formatCurrency = (value) => {
  if (value == "" || value == null || value == undefined) return "Rp 0";
  return new Intl.NumberFormat("in-IN", {
    style: "currency",
    currency: "IDR",
  }).format(value);
};

export const onlyNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)
    return evt.preventDefault();
  return true;
};

export const isNumeric = (number) => {
  if (+number === +number) {
    // if is a number
    return true;
  }

  return false;
};

export const backTo = (url, query = {}) => {
  window.location.replace(url);
};

export const checkType = (data) => {
  if (data) {
    if (parseInt(data) != NaN) {
      return parseInt(data);
    }
    return data;
  }
};

export const createNewArray = (array, keys, nested = {}) => {
  let returnArr = [];
  array.map((arr) => {
    let obj = {};
    keys.map((key) => {
      obj[key.to] = arr[key.from];
    });
    if (nested && arr[nested.from]) {
      obj[nested.to] = createNewArray(arr[nested.from], keys, nested);
    }
    returnArr.push(obj);
  });
  return returnArr;
};

export const downloadPdf = (transaction_type, id, query = {}) => {
  console.log(transaction_type);
  if (transaction_type === "marketplace_transactions") {
    API_COMMERCE.downloadPDFMarketplace(id)
      .then((response) => {
        let name = "MARKETPLACE.pdf"; //response.data.data.url.split();
        let url = response.data.url;
        var link = document.createElement("a");
        console.log(response);
        link.href = url;
        link.target = "_blank";
        link.download = name;
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {});
  } else if (transaction_type === "digital_product_transactions") {
    API_DIGIPROD.downloadPDF(id)
      .then((response) => {
        console.log(response);
        let name = response.data.name;
        let url = response.data.url;
        var link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = name;
        console.log(name);
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {});
  } else if (transaction_type === "pencairan_dana_admin") {
    console.log(id);
    API_TRANSACTION.downloadPDFPencairanDana(id)
      .then((response) => {
        console.log(response);
        let name = "PencairanDanaAdmin.pdf"; //response.data.data.url.split();
        let url = response.data.data.url;
        var link = document.createElement("a");
        console.log(response);
        link.href = url;
        link.target = "_blank";
        link.download = name;
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {});
  } else {
    API_TRANSACTION.downloadPDF(transaction_type, id, query)
      .then((response) => {
        console.log(response);
        let name = response.data.data.url.split();
        var link = document.createElement("a");
        link.download = name[name.length - 1];
        link.href = response.data.data.url;
        link.click();
      })
      .catch((err) => {});
  }
};
export const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return moment(date).format("MMMM");
};

export const subStringText = (message, start, end, a) => {
  if (message?.length >= a) {
    return message.substring(start, end) + "...";
  } else {
    return message;
  }
};

export const camelCase = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((el, ind) => {
      if (el) {
        return el[0].toUpperCase() + el.substring(1, el.length);
      }
    })
    .join(" ");
};

export const replaceText = (text, from, to) => {
  return text.replaceAll(from, to);
};

export const humanizedText = (text) => {
  return text.replaceAll("-", " ").replaceAll("_", " ");
};
